<template>
  <div class="tab-contents-wrap">
    <!-- Reservation List -->
    <div class="board-list-wrap" style="margin-top: 0">
      <template v-if="statusType === true">
      <div class="board-top-area">
        <div class="icon-notice">{{$t('content.event.EventReservation.txt124')}}</div>
        <!-- <div class="total-count">
          {{$t('content.event.EventReservation.txt125')}}
          <em class="space">{{vunues.length}}</em>{{$t('content.event.EventReservation.txt126')}}
        </div> -->
      </div>
      <ul class="board-list">
        <template v-for="(v, i) in vunues.slice(0, increase)">
          <li :key="i">
            <div class="list-item">
              <dl class="info">
                <dt>{{ v.reservedPlaceNames }}</dt>
                <dd>
                  <ul class="info-detail">
                    <li class="time">
                      <time>
                        {{ getDateString(v.startTime, v.endTime) }}
                      </time>
                    </li>
                    <li class="date">
                      <span>{{$t('content.event.EventReservation.txt127')}} :</span>
                      <time>{{$moment(v.insertDate.replace(/\./g,'-')).format('YYYY.MM.DD HH:mm')}}</time>
                    </li>
                    <li class="status">
                      <template>
                        <span>
                          {{$t('content.users.ServiceInfo.txt13')}} :
                          {{v.reservationStateName}}
                          ({{$moment(v.borrowChangeStatusDate.replace(/\./g,'-')).format('YYYY.MM.DD HH:mm')}})
                        </span>
                      </template>
                    </li>
                    <li class="file-download-title">
                      첨부파일 ({{ v.fileList.length }})
                      <a
                        class="file-upload"
                        v-if="v.fileList.length < 5"
                        href="javascript:void(0)" @click.prevent="showUpload(v.id)">
                        <span>{{$t('content.event.EventReservation.txt112_1')}}</span>
                      </a>
                    </li>
                    <li v-if="v.fileList && v.fileList.length > 0" class="file-download-wrap">
                      <ul class="file-download-list">
                        <template v-for="(file, index) in v.fileList">
                          <li class="file-link">
                            <a href="javascript:void(0)" @click.prevent="downloadFile(file.url, file.originalName)">
                              <span class="file-download-icon"></span><span class="file-download-name">{{ file.originalName }}</span>
                            </a>
                          </li>
                        </template>
                        <!-- <li v-if="v.fileList.length < 5" class="file-link">
                          <a href="javascript:void(0)" @click.prevent="showUpload(v.id)">
                            <span class="file-download-icon"></span><span>찾아보기</span>
                          </a>
                        </li>
                        <li v-if="v.fileList.length < 5" class="file-link">
                          <a href="javascript:void(0)" @click.prevent="showUpload(v.id)">
                            &nbsp;&nbsp;&nbsp;<b>+</b>&nbsp;&nbsp;<span>첨부파일 추가</span>
                          </a>
                        </li> -->
                      </ul>
                    </li>
                  </ul>
                </dd>
              </dl>
              <div class="right-area">
                <!-- <button class="btn-ghost btn-sm">신청 취소</button> -->
              </div>
            </div>
          </li>
        </template>
      </ul>
      <input
        ref="fileInput"
        type="file"
        class="hidden"
        @change="changeFile($event)"
        accept=".hwp, .doc, .docx, .ppt, .pptx, .xls, .xlsx, .txt, .csv, .jpg, .jpeg, .gif, .png, .bmp, .pdf"
      >
      <div class="news_more">
          <button class="btn_more" @click="addVunues" v-if="vunues.length>increase">
              <span class="ico_more">+</span>
              <span class="txt_more">more</span>
          </button>
      </div>
      <div class="btn_bottom_wrap">
        <button @click="goBack" class="btn-ghost">Back</button>
        <!-- <button button class="btn-more" @click="addVunues" v-if="vunues.length>increase">
          <span class="circle">
            <span class="icon-plus">+</span>
          </span>
          <em class="btn-text">{{$t('content.event.EventReservation.txt128')}}</em>
        </button> -->
      </div>
      </template>
      <template v-else>
        <div class="no-list-wrap" style="margin-top:4rem">
          <div class="no-list">
            <h6 data-aos="flip-left" class="aos-init aos-animate">{{$t('content.event.EventReservation.txt129')}}</h6>
          </div>
          <div class="btn_bottom_wrap">
            <button @click="goBack" class="btn-ghost">Back</button>
          </div>
        </div>
      </template>
    </div>
    <!-- Reservation List -->
  </div>
</template>
<script>
import History from '@/views/reservation/History.vue';

export default {
  name: 'MobileReservationHistory',
  extends: History,
};
</script>
<style scoped>
.board-list .list-item .info .info-detail .date{ padding-right: 6rem;}
.board-list-wrap{ margin-top: 4rem;}
.attachment {
  margin-top: 1rem;
}
.file-download-list {
  display: inline-block;
  width: 100%;
  vertical-align: top;
  margin-top: 10px;
  border-top: 1px solid #ddd;
  padding: 10px 0;
}
.file-download-icon {
  display: inline-block;
  background-image: url(/img/icons_etc.06eea5d0.png);
  background-repeat: no-repeat;
  background-size: auto 3.24rem;
  overflow: hidden;
  white-space: nowrap;
  text-indent: -9999px;
  width: 3rem;
  height: 3rem;
  z-index: 2;
  background-position: -2rem -0.3rem;
  vertical-align: middle;
}
.file-download-name {
  vertical-align: middle;
}
.file-download-wrap {
  width: 100%;
  margin-top: 1rem;
  text-align: left;
}
input.hidden {
  display: none;
  width: 0;
  height: 0;
  position: absolute;
  top: -99999;
  left: -99999;
  z-index: 2;
}
.file-download-title .file-upload {
  display: inline-block;
  width: 70px;
  height: 26px;
  text-align: center;
  font-size: 12px;
  font-weight: 700;
  line-height: 26px;
  background-color: #fff;
  cursor: pointer;
  border: 1px solid #000;
  margin-left: 10px;
}
</style>
