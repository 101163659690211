<template>
  <div class="tab-contents-wrap">
    <!-- Reservation List -->
    <div class="board-list-wrap">
      <template v-if="statusType === true">
      <div class="board-top-area">
        <div class="icon-notice">{{$t('content.event.EventReservation.txt124')}}</div>
        <div class="total-count">
          {{$t('content.event.EventReservation.txt125')}}
          <em class="space">{{vunues.length}}</em>{{$t('content.event.EventReservation.txt126')}}
        </div>
      </div>
      <ul class="board-list">
        <template v-for="(v, i) in vunues.slice(0, increase)">
          <li>
            <div class="list-item">
              <dl class="info">
                <dt>{{v.reservedPlaceNames}}</dt>
                <dd>
                  <ul class="info-detail">
                    <li class="date">
                      <time>
                        {{ getDateString(v.startTime, v.endTime, $i18n.locale) }}
                      </time>
                    </li>
                    <li>
                      <span>{{$t('content.event.EventReservation.txt127')}} :</span>
                      <time>{{$moment(v.insertDate).format('YYYY.MM.DD HH:mm')}}</time>
                    </li>
                    <li class="v-bar">
                      <!-- <span>{{$t('content.users.ServiceInfo.txt13')}} :</span> -->
                      <!-- <template v-if="v.reservationState === '9010'">
                        <span>
                          {{v.reservationStateName}}
                        </span>
                      </template> -->
                      <template>
                        <span>
                          {{$t('content.users.ServiceInfo.txt13')}} :
                          {{v.reservationStateName}}
                          ({{$moment(v.borrowChangeStatusDate).format('YYYY.MM.DD HH:mm')}})
                        </span>
                      </template>
                    </li>
                  </ul>
                </dd>
                <dd>
                  <li class="file-download-title">첨부파일 ({{ v.fileList.length }})
                    <a
                      class="file-upload"
                      v-if="v.fileList.length < 5"
                      href="javascript:void(0)" @click.prevent="showUpload(v.id)">
                      <span>{{$t('content.event.EventReservation.txt112_1')}}</span>
                    </a>
                  </li>
                  <li>
                    <template v-if="v.fileList && v.fileList.length > 0">
                      <ul class="file-download-list">
                        <template v-for="(file, index) in v.fileList">
                          <li class="file-link">
                            <a href="javascript:void(0)" @click.prevent="downloadFile(file.url, file.originalName)">
                              <span class="file-download-icon"></span><span class="file-download-name">{{ file.originalName }}</span>
                            </a>
                          </li>
                        </template>
                      </ul>
                    </template>
                  </li>
                </dd>
              </dl>
              <div class="right-area">
                <!-- <button class="btn-ghost btn-sm">신청 취소</button> -->
              </div>
            </div>
          </li>
        </template>
      </ul>
      <div class="btn-wrap">
        <a href="#" @click="goBack" class="back">Back</a>
        <button button class="btn-more" @click="addVunues" v-if="vunues.length>increase">
          <span class="circle">
            <span class="icon-plus">+</span>
          </span>
          <em class="btn-text">{{$t('content.event.EventReservation.txt128')}}</em>
        </button>
      </div>
      </template>
      <template v-else>
        <div class="no-list-wrap">
          <div class="no-list">
            <h6 data-aos="flip-left" class="aos-init aos-animate">{{$t('content.event.EventReservation.txt129')}}</h6>
          </div>
          <div class="btn-wrap">
            <a href="#" @click="goBack" class="back">Back</a>
          </div>
        </div>
      </template>
    </div>
    <!-- Reservation List -->
    <input
      ref="fileInput"
      type="file"
      class="hidden"
      @change="changeFile($event)"
      accept=".hwp, .doc, .docx, .ppt, .pptx, .xls, .xlsx, .txt, .csv, .jpg, .jpeg, .gif, .png, .bmp, .pdf"
    >
  </div>
</template>

<script>
import { APIs, FileCategory } from '@/common/portalApi';
import { mixin as mixinHelperUtils, mixinMotion, getDateString } from '@/common/helperUtils';
import FileInput from '@/components/base/FileInput.vue';

export default {
  name: 'ReservationHistory',
  mixins: [mixinHelperUtils, mixinMotion],
  components: {
    FileInput
  },
  metaInfo() {
    return {
      meta: this.$route.meta.metaInfo
    };
  },
  data() {
    return {
      vunues: [],
      statusType: true,
      increase: 10,
    };
  },
  created() {
    this.retrieveVenue();
    this.getDateString = getDateString;
  },
  methods: {
    retrieveVenue() {
      this.portalApiClient({
        url: APIs.MYPAGE_VENUE_LIST,
        data: {
          data: {
            memberId: this.$store.getters.getUserInfo().id
          },
          lang: this.$i18n.locale
        },
      }).then(({ data }) => {
        this.vunues = data.list;
        this.statusType = !!data.list.length;
      });
    },
    getVenueName(codes) {
      const venues = [];
      codes.forEach((v) => {
        venues.push(this.$t(`commonCode.${v.code_place_event}`));
      });
      return venues.join(', ');
    },
    addVunues() {
      this.increase += 10;
    },
    goBack() {
      this.$router.go(-1);
    },
    async selectFile(file, reservationId) {
      console.log(file)
      if (file) {
        if (file.size > 10485760) {
          this.showAlert(this.$t('content.event.EventReservation.alertMsg.msg4'));
          return;
        }
        // 파일 형식 체크
        const res = await this.uploadFile(
          FileCategory.EVENTHALL,
          2, // attachement
          reservationId, // parentId
          file,
        )

        if (res.result) {
          this.retrieveVenue();
        } else if (res.code == '202') {
          this.showAlert('파일 형식은 ".hwp, .doc, .docx, .ppt, .pptx, .xls, .xlsx, .txt, .csv, .jpg, .jpeg, .gif, .png, .bmp, .pdf"으로 가능합니다');
        }
      }
    },
    showUpload(reservationId) {
      this.reservationId = reservationId;
      this.showConfirm('첨부파일을 업로드 하시겠습니까?', () => {
        this.$refs.fileInput.click();
      });
    },
    changeFile(event) {
      const fileData = event.target.files[0];
      this.selectFile(fileData, this.reservationId)
    },
    removeFile() {
      // this.retrieveVenue();
    },
  },
};
</script>
<style scoped>
.file-download-list {
  display: inline-block;
  width: 100%;
  margin-top: 10px;
  border-top: 1px solid #ddd;
  padding: 10px 0;
}
.file-download-icon {
  display: inline-block;
  background-image: url(/img/icons_etc.06eea5d0.png);
  background-repeat: no-repeat;
  background-size: auto 2.16rem;
  overflow: hidden;
  white-space: nowrap;
  text-indent: -9999px;
  width: 1.5rem;
  height: 2rem;
  z-index: 2;
  background-position: -1.6rem -0.2rem;
  vertical-align: middle;
}

.file-download-title .file-upload {
  display: inline-block;
  width: 80px;
  height: 35px;
  text-align: center;
  font-size: 12px;
  font-weight: 700;
  line-height: 35px;
  background-color: #fff;
  cursor: pointer;
  border: 1px solid #000;
  margin-left: 10px;
}
</style>
